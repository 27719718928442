
import {PropType, defineComponent} from 'vue';
import Script from '@/models/Script';
import {toPlainText} from '@/library/quill/quillDelta';
import {wordCounter} from '@/library/helpers';

export default defineComponent({
    props: {
        script: {
            type: Object as PropType<Script>,
            required: true,
        },
    },
    computed: {
        status(): string | null {
            if (!this.script.active) {
                return 'disabled';
            }

            if (this.script.confirmedAt) {
                return 'saved';
            }

            if (wordCounter(toPlainText(this.script.body)) >= this.script.maxWords * 0.1) {
                return 'semi-filled';
            }

            return null;
        },
    },
});
