
import User, {Users} from '@/models/User';
import {defineComponent, ref} from 'vue';
import UsersList from '@/components/users/UsersList.vue';
import auth from '@/store/auth';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        UsersList,
    },
    setup() {
        return {
            users: ref<Users>(new Users()),
            pitch: store.pitch,
            me: auth.me,
        };
    },
    computed: {
        otherUsers(): Users {
            // Filter the list of users to remove the current user.
            return this.users.filter((user: User) => user.id !== auth.me.value!.id);
        },
    },
    mounted() {
        const {channel} = this.pitch.websocket;

        if (!channel) {
            return;
        }

        channel.here((users: Record<string, any>[]) => {
            users.forEach((user: Record<string, any>) => {
                this.users.push(new User(user));
            });
        });

        channel.joining((user: Record<string, any>) => {
            const newUser = new User(user);

            this.users.push(newUser);
        });

        channel.leaving((user: Record<string, any>) => {
            const leavingUser = new User(user);

            this.users.removeFirstWhere((u: User) => u.id === leavingUser.id);
        });
    },
});
