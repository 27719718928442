import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a8f496a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.key,
        class: "nav-item"
      }, [
        _createElementVNode("button", {
          class: "btn btn-sm instruction-action",
          onClick: _withModifiers(($event: any) => (_ctx.setCurrentScriptAction(item.key)), ["stop"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t(item.translationKey)) + " ", 1),
          _createVNode(_component_Fa, {
            icon: item.icon
          }, null, 8, ["icon"])
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}