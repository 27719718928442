import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionNavBtn = _resolveComponent("ActionNavBtn")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scriptActions, (action, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: action.key,
        class: _normalizeClass(["nav-item", {'ms-2': index}])
      }, [
        _createVNode(_component_ActionNavBtn, { action: action }, null, 8, ["action"])
      ], 2))
    }), 128))
  ]))
}