import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, {
    label: _ctx.$t('pitch.share.setAccessLevel')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dropdown, {
        "emit-key": "key",
        "full-width": "",
        "menu-class": "w-100",
        options: _ctx.permissionOptions,
        title: _ctx.dropdownTitle,
        onOptionSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["options", "title"])
    ]),
    _: 1
  }, 8, ["label"]))
}