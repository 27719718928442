
import NavDraggable from '@/components/pitches/edit/scripts/NavDraggable.vue';
import NavToggle from '@/components/pitches/edit/scripts/NavToggle.vue';
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        NavDraggable,
        NavToggle,
    },
    setup() {
        return {
            scriptNavVisible: store.scriptNavVisible,
        };
    },
});
