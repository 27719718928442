
import {PropType, defineComponent} from 'vue';
import store, {useScriptStore} from '@/store/pitches/edit';
import Script from '@/models/Script';
import StatusIndicator from '@/components/scripts/StatusIndicator.vue';
import {toPlainText} from '@/library/quill/quillDelta';
import {wordCounter} from '@/library/helpers';

export default defineComponent({
    components: {
        StatusIndicator,
    },
    props: {
        script: {
            type: Object as PropType<Script>,
            required: true,
        },
    },
    setup(props) {
        const {isCurrentScript} = useScriptStore(props.script);
        const {canEdit, setCurrentScript, toggleScript} = store;

        return {
            isCurrentScript,
            canEdit,
            setCurrentScript,
            toggleScript,
        };
    },
    computed: {
        isInactive(): boolean {
            return !this.isCurrentScript
                && !this.script.confirmedAt
                && !wordCounter(toPlainText(this.script.body));
        },
    },
});
