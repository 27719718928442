import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b46a398"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-auto border rounded-3 ms-n1px my-n1px" }
const _hoisted_2 = { class: "col text-truncate mx-4" }
const _hoisted_3 = { class: "row gx-3 align-items-center" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = {
  key: 1,
  class: "controls col-auto ps-0 text-muted"
}
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_StatusIndicator = _resolveComponent("StatusIndicator")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row g-0 border btn-script rounded-3 flex-nowrap align-items-center", {'bg-light border-light': _ctx.isCurrentScript}]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCurrentScript(_ctx.script)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Fa, {
        class: "drag-handle cursor-grab px-3 py-4 text-muted",
        icon: "grip-vertical",
        size: "lg"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_StatusIndicator, { script: _ctx.script }, null, 8, ["script"])
        ]),
        (_ctx.script.subject)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["subject-name col text-truncate", {'opacity-20': _ctx.isInactive, 'text-body fw-bold': _ctx.isCurrentScript}])
            }, _toDisplayString(_ctx.script.subject.name), 3))
          : _createCommentVNode("", true),
        (_ctx.canEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createElementBlock("span", {
                class: "me-2",
                title: _ctx.$t(`script.${_ctx.script.active ? 'disable' : 'enable'}Chapter`)
              }, [
                _createVNode(_component_Fa, {
                  "data-test-id": "btn-toggle-active",
                  icon: _ctx.script.active ? 'eye-closed' : 'eye',
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleScript(_ctx.script)), ["stop"]))
                }, null, 8, ["icon"])
              ], 8, _hoisted_6)), [
                [_directive_tooltip]
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}