import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn btn-sm btn-square btn-outline-light", {active: _ctx.active}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setScriptAction && _ctx.setScriptAction(...args)))
  }, [
    _createVNode(_component_Fa, { icon: _ctx.icon }, null, 8, ["icon"])
  ], 2)), [
    [
      _directive_tooltip,
      _ctx.$t(_ctx.action.tooltip),
      void 0,
      { hover: true }
    ]
  ])
}