
import {defineComponent, ref} from 'vue';
import FormSwitch from '@/components/common/form/FormSwitch.vue';
import {Tooltip} from 'bootstrap';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        FormSwitch,
    },
    props: {
        // The time (in ms) after which the tooltip will be shown.
        tooltipDelay: {
            type: Number,
            default: 180 * 1000,
        },
    },
    setup() {
        return {
            inFocusMode: store.inFocusMode,
            tooltipTimer: 0,
            tooltip: ref<Tooltip>(),
        };
    },
    watch: {
        inFocusMode(value: boolean) {
            if (value) {
                this.showTooltip();
            } else {
                this.hideTooltip();
            }
        },
    },
    mounted() {
        this.tooltip = new Tooltip(this.$el, {
            customClass: 'focus-tooltip whitespace-nowrap',
            offset: [0, 7],
            placement: 'bottom',
            title: this.$t('script.focusTooltip'),
            trigger: 'manual',
        });
    },
    beforeUnmount() {
        if (this.tooltip) {
            this.tooltip.dispose();
        }
    },
    methods: {
        hideTooltip() {
            clearTimeout(this.tooltipTimer);

            if (this.tooltip) {
                this.tooltip.hide();
            }
        },
        showTooltip() {
            this.hideTooltip();

            this.tooltipTimer = setTimeout(() => {
                if (!this.tooltip) {
                    return;
                }

                this.tooltip.show();

                /*
                 * This is needed the first time the tooltip gets shown.
                 * We need to move the close icon to within the tooltip.
                 */
                const tooltipContainer = document.querySelector('.focus-tooltip > .tooltip-inner');

                const closeButton = this.$el.querySelector('.focus-tooltip-close');

                if (tooltipContainer && closeButton) {
                    closeButton.classList.remove('d-none');

                    tooltipContainer.appendChild(closeButton);
                }
            }, this.tooltipDelay);
        },
    },
});
