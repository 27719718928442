import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "col-auto" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "text-secondary" }
const _hoisted_5 = { class: "col-auto text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invitations, (invitation) => {
      return (_openBlock(), _createElementBlock("div", {
        key: invitation.id,
        class: "row mb-4 align-items-center"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ProfilePicture, {
            class: "square-sm rounded-circle",
            user: invitation.user
          }, null, 8, ["user"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(invitation.user.name), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(invitation.user.email), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Dropdown, {
            align: "end",
            "emit-key": "key",
            options: _ctx.shareOptions,
            title: _ctx.getInvitationPermission(invitation),
            variant: null,
            onOptionSelected: ($event: any) => (_ctx.selectOption(invitation, $event))
          }, null, 8, ["options", "title", "onOptionSelected"])
        ])
      ]))
    }), 128))
  ]))
}