import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toHandlerKey as _toHandlerKey, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-708678fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "btn btn-square btn-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_InstructionActionNav = _resolveComponent("InstructionActionNav")!
  const _component_Fa = _resolveComponent("Fa")!

  return (_ctx.instruction)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["card bg-light cursor-pointer", {'rounded-2': !_ctx.open}]),
        [_toHandlerKey(_ctx.toggleEvent)]: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOpen()))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["card-body", {'py-4': !_ctx.isCurrentScript}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["row card-body-content overflow-hidden", {'align-items-center': !_ctx.isCurrentScript, open: _ctx.open}])
          }, [
            (_ctx.instruction.user && _ctx.isCurrentScript)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ProfilePicture, {
                    class: "square-sm rounded-circle",
                    user: _ctx.instruction.user
                  }, null, 8, ["user"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Markdown, {
                class: _normalizeClass({'text-muted': !_ctx.isCurrentScript}),
                source: _ctx.body
              }, null, 8, ["class", "source"]),
              (_ctx.open)
                ? (_openBlock(), _createBlock(_component_InstructionActionNav, {
                    key: 0,
                    class: "nav mt-4"
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", _hoisted_4, [
                _createVNode(_component_Fa, {
                  class: _normalizeClass(["text-muted transition", {'fa-rotate-180': _ctx.open}]),
                  icon: "chevron-down"
                }, null, 8, ["class"])
              ])
            ])
          ], 2)
        ], 2)
      ], 16))
    : _createCommentVNode("", true)
}