
import {PropType, defineComponent} from 'vue';
import {cannon, stagedCannons} from '@/library/helpers';
import store, {useScriptStore} from '@/store/pitches/edit';
import Script from '@/models/Script';
import kazoo from '@/assets/audio/kazoo.mp3';

export default defineComponent({
    props: {
        script: {
            type: Object as PropType<Script>,
            required: true,
        },
    },
    setup(props) {
        const {wordCount} = useScriptStore(props.script);

        return {
            activeScripts: store.activeScripts,
            confirmScriptAndContinue: store.confirmScriptAndContinue,
            getActiveScriptIndex: store.getActiveScriptIndex,
            wordCount,
        };
    },
    computed: {
        isLastScript(): boolean {
            const index = this.getActiveScriptIndex(this.script);
            return index !== false
                && index + 1 === this.activeScripts.count();
        },
        opacity(): number {
            return this.wordCount / (this.script.maxWords * 0.6);
        },
        text(): string {
            return this.$t(`actions.confirm${!this.isLastScript ? 'AndContinue' : ''}`);
        },
    },
    methods: {
        async confirmScript() {
            this.confirmScriptAndContinue(this.script);

            if (this.isLastScript) {
                this.celebrate();
            }
        },
        celebrate() {
            cannon();

            stagedCannons();

            // 0.1% chance to play the kazoo sound
            if (Math.floor(Math.random() * 1000) !== 0) {
                return;
            }

            const kazooAudio = new Audio(kazoo);

            kazooAudio.play();
        },
    },
});
