import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: "btn btn-outline-light btn-sm btn-square",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scriptNavVisible = !_ctx.scriptNavVisible))
  }, [
    _createVNode(_component_Fa, {
      icon: "hamburger",
      size: "lg"
    })
  ])), [
    [
      _directive_tooltip,
      _ctx.$t('pitch.edit.subjects'),
      void 0,
      { hover: true }
    ]
  ])
}