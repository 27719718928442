import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-1 text-muted small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillInputEmail = _resolveComponent("PillInputEmail")!
  const _component_CreateInvitations = _resolveComponent("CreateInvitations")!
  const _component_ShowInvitations = _resolveComponent("ShowInvitations")!
  const _component_Error = _resolveComponent("Error")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "cancel-title": _ctx.$t('actions.cancel'),
    "close-on-cancel": !_ctx.showCreateInvitations,
    name: _ctx.modalName,
    "no-cancel-btn": !_ctx.showCreateInvitations,
    "ok-title": _ctx.okTitle,
    title: _ctx.$t('pitch.share.title'),
    onCancel: _ctx.hideCreateInvitations
  }, {
    "ok-button": _withCtx(({title}) => [
      _createVNode(_component_BtnResource, {
        "data-test-id": "btn-share-modal-submit",
        resource: _ctx.invitationUpdates,
        onClick: _ctx.saveInvitations
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(title), 1)
        ]),
        _: 2
      }, 1032, ["resource", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_WaitForResource, { resource: _ctx.invitations }, {
        default: _withCtx(() => [
          _createVNode(_component_PillInputEmail, {
            modelValue: _ctx.invitationEmails,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invitationEmails) = $event)),
            placeholder: _ctx.$t('pitch.share.addPeople'),
            onFocusin: _cache[1] || (_cache[1] = ($event: any) => {_ctx.showCreateInvitations = true;})
          }, null, 8, ["modelValue", "placeholder"]),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('sentences.inputEmailSeparatedBy')), 1),
          (_ctx.showCreateInvitations)
            ? (_openBlock(), _createBlock(_component_CreateInvitations, {
                key: 0,
                modelValue: _ctx.newInvitationsPermission,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newInvitationsPermission) = $event)),
                class: "mt-6",
                "data-test-id": "create-invitations"
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_ShowInvitations, {
                key: 1,
                class: "mt-6",
                "data-test-id": "show-invitations",
                invitations: _ctx.invitations,
                onUpdateInvitationPermission: _ctx.updatePermissions
              }, null, 8, ["invitations", "onUpdateInvitationPermission"])),
          _createVNode(_component_Error, {
            class: "mt-4 d-block",
            errors: _ctx.invitationUpdates.errors
          }, null, 8, ["errors"])
        ]),
        _: 1
      }, 8, ["resource"])
    ]),
    _: 1
  }, 8, ["cancel-title", "close-on-cancel", "name", "no-cancel-btn", "ok-title", "title", "onCancel"]))
}