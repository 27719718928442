
import Edit from '@/components/pitches/edit/scripts/Edit.vue';
import Script from '@/models/Script';
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        Edit,
    },
    setup() {
        return {
            activeScripts: store.activeScripts,
            currentScript: store.currentScript,
            setCurrentScript: store.setCurrentScript,
        };
    },
    watch: {
        currentScript(newScript: Script) {
            const script = document.getElementById(`script-${newScript.id}`);

            if (!script) {
                return;
            }

            this.$nextTick(() => {
                script.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            });
        },
    },
});
