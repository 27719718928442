import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn btn-outline-light btn-sm", {'pe-none': _ctx.wordCount === 0}]),
    style: _normalizeStyle({opacity: _ctx.opacity}),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmScript && _ctx.confirmScript(...args)))
  }, _toDisplayString(_ctx.text), 7))
}