import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersList = _resolveComponent("UsersList")!

  return (_ctx.otherUsers.count())
    ? (_openBlock(), _createBlock(_component_UsersList, {
        key: 0,
        class: "d-flex",
        "max-amount": 4,
        users: _ctx.otherUsers
      }, null, 8, ["users"]))
    : _createCommentVNode("", true)
}