import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItem = _resolveComponent("NavItem")!
  const _component_Draggable = _resolveComponent("Draggable")!

  return (_openBlock(), _createBlock(_component_Draggable, {
    modelValue: _ctx.scripts.items,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scripts.items) = $event)),
    "ghost-class": "draggable-ghost",
    handle: ".drag-handle",
    "item-key": "id",
    onEnd: _ctx.updateIndex
  }, {
    item: _withCtx(({element}) => [
      _createVNode(_component_NavItem, {
        class: "w-100 mb-2",
        script: element
      }, null, 8, ["script"])
    ]),
    _: 1
  }, 8, ["modelValue", "onEnd"]))
}