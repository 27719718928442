import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column max-h-100 overflow-hidden" }
const _hoisted_2 = { class: "overflow-y-auto no-scrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsNav = _resolveComponent("ActionsNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionsNav, { class: "mb-2 justify-content-end" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        mode: "out-in",
        name: "fade-right"
      }, {
        default: _withCtx(() => [
          (_ctx.componentIsVisible)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                key: 0,
                script: _ctx.currentScript
              }, null, 8, ["script"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}