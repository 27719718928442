import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0515a796"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "name-input-wrapper",
    "data-placement": "bottom",
    "data-value": _ctx.name
  }, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
      class: _normalizeClass(["form-control p-1 fs-5 fw-bolder", {'is-invalid': _ctx.invalid}]),
      "data-test-id": "input-name",
      size: "4",
      type: "text",
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateName && _ctx.updateName(...args))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focussed = true)),
      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.input?.blur && _ctx.input?.blur(...args)), ["prevent"]), ["enter"]))
    }, null, 34), [
      [_vModelText, _ctx.name]
    ])
  ], 8, _hoisted_1)), [
    [
      _directive_tooltip,
      _ctx.focussed ? '' : _ctx.tooltip,
      void 0,
      { hover: true }
    ]
  ])
}