import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareModal = _resolveComponent("ShareModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      class: "btn btn-outline-light",
      "data-bs-target": "#pitch-share",
      "data-bs-toggle": "modal"
    }), _toDisplayString(_ctx.$t('pitch.actions.share')), 17),
    _createVNode(_component_ShareModal)
  ], 64))
}