
import AmountOf from '@/components/common/AmountOf.vue';
import Script from '@/models/Script';
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';
import {wordsPerMinute} from '@/library/helpers';

export default defineComponent({
    components: {
        AmountOf,
    },
    computed: {
        maxMinutes(): number {
           return wordsPerMinute(this.maxWords);
        },
        maxWords(): number {
            return store.pitch
                .value
                .scripts
                .reduce((sum: number, s: Script) => sum + s.maxWords, 0);
        },
        minuteCount(): number {
            return wordsPerMinute(this.wordCount);
        },
        wordCount(): number {
            return Object
                .values(store.wordCounts.value)
                .reduce((sum: number, count: number) => sum + count, 0);
        },
    },
});
