
import {PropType, defineComponent, ref} from 'vue';
import Quill, {RangeStatic} from 'quill';
import {checkDeleteComments, createComment, removeHighlight} from '@/components/common/quill/ManageComments';
import {keyboardOverrides, overrideEventListeners, removeOverrideEventListeners} from '@/components/common/quill/AllowOnlyComments';
import Delta from 'quill-delta';
import emitter from '@/vendor/mitt';
import isEqual from 'lodash/isEqual';
import matchers from '@/components/common/quill/ClipboardMatchers';

/**
 * Creates a Rich Text Editor component.
 * Makes use of the 'quill' package to create the editor component.
 * Is built to support live editing by websockets.
 * Emits 'selection-change' when the user changes the text selection.
 * Emits 'text-change' when the text has changed.
 * Emits 'update:modelValue' when the text has changed.
 */
export default defineComponent({
    props: {
        canComment: {
            type: Boolean,
            default: true,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object as PropType<Delta>,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    emits: [
        'selection-change',
        'text-change',
        'update:modelValue',
    ],
    setup() {
        return {
            editor: ref<HTMLDivElement>(),
            toolbar: ref<HTMLDivElement>(),
            quill: undefined as Quill|undefined,
            checkDeleteComments,
            createComment,
            removeHighlight,
        };
    },
    watch: {
        modelValue(newValue: Delta) {
            if (!this.quill) {
                return;
            }

            const diff = this.quill.getContents().diff(newValue);

            this.quill.updateContents(diff, 'silent');
        },
    },
    mounted() {
        emitter.on('removeCommentHighlight', this.removeCommentHighlight);
        emitter.on('highlightBlotDeleted', this.highlightBlotDeleted);

        const modules: Record<string, any> = {
            toolbar: this.toolbar,
            clipboard: {
                matchers,
            },
            history: {
                userOnly: true,
                maxStack: 0,
            },
            cursors: {
                transformOnTextChange: true,
            },
            keyboard: {
                bindings: keyboardOverrides,
            },
        };

        if (this.canEdit) {
            delete modules.history.maxStack;

            delete modules.keyboard;
        }

        this.quill = new Quill(this.editor as HTMLElement, {
            modules,
            placeholder: this.placeholder,
            formats: [
                'bold',
                'italic',
                'underline',
                'list',
                'align',
                'highlight',
            ],
            theme: 'bubble',
            bounds: '#quill-text-editor',
            readOnly: true,
        });

        const contentEditor = this.editor!.querySelector('.ql-editor');

        if (contentEditor) {
            overrideEventListeners(contentEditor);
        }

        this.quill.root.setAttribute('spellcheck', 'false');

        this.quill.setContents(this.modelValue, 'silent');

        this.quill.on('text-change', (delta: Delta, oldContents: Delta, source: string) => {
            this.$emit('update:modelValue', this.quill!.getContents());
            this.$emit('text-change', {delta, oldContents, source});
        });

        this.quill.on('selection-change', (range: RangeStatic, oldRange: RangeStatic, source: string) => {
            if (isEqual(range, oldRange)) {
                return;
            }

            this.$emit('selection-change', {range, oldRange, source});
        });

        if (this.canComment) {
            this.quill.enable();
        }

        if (this.canEdit && contentEditor) {
            removeOverrideEventListeners(contentEditor);
        }
    },
    beforeUnmount() {
        emitter.off('removeCommentHighlight', this.removeCommentHighlight);
        emitter.off('highlightBlotDeleted', this.highlightBlotDeleted);
    },
    methods: {
        focus() {
            this.quill!.focus();
        },
        createNewComment() {
            // If this is not the active quill editor, don't create a new comment.
            if (!this.isActive) {
                return;
            }

            this.createComment(this.quill!);
        },
        removeCommentHighlight() {
            // If this is not the active quill editor, don't remove the highlight.
            if (!this.isActive) {
                return;
            }

            this.removeHighlight(this.quill!);
        },
        async highlightBlotDeleted(uuid: string) {
            // If this is not the active quill editor, don't check to delete comments.
            if (!this.isActive || uuid == null) {
                return;
            }

            /*
             * Wait for the next tick to check for deleted comments. This is needed
             * so we get the quill content after all highlight blots have been
             * removed, in case two or more blots are deleted in the same action.
             */
            await this.$nextTick();

            this.checkDeleteComments(uuid, this.quill!.getContents());
        },
    },
});
