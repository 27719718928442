
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    setup() {
        return {
            scriptNavVisible: store.scriptNavVisible,
        };
    },
});
