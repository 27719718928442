
import Invitation, {Invitations} from '@/models/Invitation';
import {PropType, defineComponent} from 'vue';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import {EnumObject} from '@/library/enumerations/Enumeration';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import SharePermission from '@/library/enumerations/SharePermission';

export default defineComponent({
    components: {
        Dropdown,
        ProfilePicture,
    },
    props: {
        invitations: {
            type: Object as PropType<Invitations>,
            required: true,
        },
    },
    emits: [
        'update-invitation-permission',
    ],
    computed: {
        shareOptions(): DropdownOption[] {
            const permissionOptions = SharePermission.objects.map((permission: EnumObject) => {
                const permissionTranslation = SharePermission.translateKey(permission.key);

                return {
                    key: permission.value,
                    label: this.$t('pitch.share.canPermission', [permissionTranslation]),
                };
            });

            return [
                ...permissionOptions,
                {
                    key: 'remove',
                    label: this.$t('pitch.share.actions.remove'),
                },
            ];
        },
    },
    methods: {
        getInvitationPermission(invitation: Invitation): string {
            if (!invitation.permissions) {
                return '';
            }

            const firstPermission = typeof invitation.permissions === 'string'
                ? invitation.permissions
                : invitation.permissions[0];

            const option = this.shareOptions.find((option: DropdownOption) => option.key === firstPermission);

            return option?.label || '';
        },
        selectOption(invitation: Invitation, permissionKey: string|number) {
            this.$emit('update-invitation-permission', {
                id: invitation.id,
                permissionKey,
            });
        },
    },
});
