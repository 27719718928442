
import {Component, defineComponent, markRaw} from 'vue';
import store, {ScriptActionKey} from '@/store/pitches/edit';
import ActionsNav from '@/components/pitches/edit/scripts/ActionsNav.vue';
import Comments from '@/components/pitches/edit/scripts/Comments.vue';
import Tips from '@/components/pitches/edit/scripts/Tips.vue';
import Videos from '@/components/pitches/edit/scripts/Videos.vue';

const components: Record<ScriptActionKey, Component> = {
    comments: Comments,
    tips: Tips,
    videos: Videos,
};

export default defineComponent({
    components: {
        ActionsNav,
    },
    setup() {
        return {
            currentScript: store.currentScript,
            currentScriptAction: store.currentScriptAction,
            inFocusMode: store.inFocusMode,
        };
    },
    computed: {
        component(): Component|undefined {
            if (!this.currentScriptAction) {
                return undefined;
            }

            /*
             * Vue does not like the value of "is" attribute to be reactive.
             * For now this is the only way I could get rid of the warning.
             */
            return markRaw(components[this.currentScriptAction.key]);
        },
        componentIsVisible(): boolean {
            return !this.inFocusMode && !!this.currentScriptAction && !!this.currentScript;
        },
    },
});
