
import {PropType, defineComponent, ref} from 'vue';
import InstructionActionNav from '@/components/pitches/edit/scripts/InstructionActionNav.vue';
import Markdown from '@/components/common/Markdown.vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import Resource from '@/models/Resource';
import ResourceType from '@/library/enumerations/ResourceType';
import Script from '@/models/Script';
import {replaceText} from '@/components/pitches/edit/scripts/InstructionInterpolation';
import {useScriptStore} from '@/store/pitches/edit';

export default defineComponent({
    components: {
        InstructionActionNav,
        Markdown,
        ProfilePicture,
    },
    props: {
        script: {
            type: Object as PropType<Script>,
            required: true,
        },
    },
    emits: [
        'toggle-instruction',
    ],
    setup(props) {
        const {isCurrentScript} = useScriptStore(props.script);

        return {
            isCurrentScript,
            open: ref(isCurrentScript.value),
        };
    },
    computed: {
        body(): string {
            if (!this.instruction) {
                return '';
            }

            return replaceText(this.isCurrentScript ? this.instruction.body : this.instruction.title);
        },
        instruction(): Resource|undefined {
            return this.script
                .subject
                ?.resources
                .firstWhere('type', ResourceType.INSTRUCTION);
        },
        toggleEvent(): string|null {
            /*
             * If the current script is not active, the instruction collapse
             * should not have a click event listener.
             */
            return this.isCurrentScript ? 'click' : null;
        },
    },
    watch: {
        isCurrentScript(value: boolean) {
            this.toggleOpen(value);
        },
    },
    methods: {
        toggleOpen(value?: boolean) {
            this.open = value !== undefined
                ? value
                : !this.open;

            this.$emit('toggle-instruction');
        },
    },
});
