import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SaveModal = _resolveComponent("SaveModal")!

  return (_openBlock(), _createBlock(_component_SaveModal, {
    "disable-template": "",
    "modal-name": _ctx.modalName,
    "ok-title": _ctx.$t('pitch.edit.updatePitch'),
    "reference-pitch": _ctx.pitch,
    title: _ctx.$t('pitch.edit.title'),
    onSubmit: _ctx.pitchUpdated
  }, null, 8, ["modal-name", "ok-title", "reference-pitch", "title", "onSubmit"]))
}