/* eslint-disable import/prefer-default-export */
import authStore from '@/store/auth';
import {t} from '@/vendor/I18n';

interface TextReplacement {
    key: string;
    value: string|(() => string);
}

const replacements: TextReplacement[] = [
    {
        key: '<user.firstName>',
        value: () => authStore.me.value?.firstName || t('users.guest'),
    },
];

const replaceText = (text: string): string => {
    let copy = text;

    replacements.forEach((replacement: TextReplacement) => {
        const value = typeof replacement.value === 'string'
            ? replacement.value
            : replacement.value();

        copy = copy.replaceAll(replacement.key, value);
    });

    return copy;
};

export {
    replaceText,
};
