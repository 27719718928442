
import Invitation, {InvitationSync, Invitations} from '@/models/Invitation';
import {defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import CreateInvitations from '@/components/pitches/edit/share/CreateInvitations.vue';
import Error from '@/components/common/Error.vue';
import {ListItem} from '@/components/common/form/PillInput.vue';
import Modal from '@/components/common/Modal.vue';
import PillInputEmail from '@/components/common/form/PillInputEmail.vue';
import SharePermission from '@/library/enumerations/SharePermission';
import ShowInvitations from '@/components/pitches/edit/share/ShowInvitations.vue';
import {hideModal} from '@/library/helpers';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        BtnResource,
        CreateInvitations,
        Error,
        Modal,
        PillInputEmail,
        ShowInvitations,
    },
    setup() {
        return {
            modalName: 'pitch-share',
            invitationEmails: ref<ListItem[]>([]),
            invitationUpdates: ref<Invitation>(new Invitation()),
            invitations: ref<Invitations>(new Invitations()),
            newInvitationsPermission: ref<number>(SharePermission.VIEW),
            showCreateInvitations: ref<boolean>(false),
        };
    },
    computed: {
        okTitle(): string {
            return this.showCreateInvitations
                ? this.$t('pitch.share.actions.sendInvite')
                : this.$t('pitch.share.actions.done');
        },
    },
    mounted() {
        this.$el.addEventListener('show.bs.modal', this.resetInvitations);
    },
    beforeUnmount() {
        this.$el.removeEventListener('show.bs.modal', this.resetInvitations);
    },
    methods: {
        /*
         * When the modal is shown, reset the properties so the modal doesn't
         * show any previous data.
         */
        resetInvitations() {
            this.showCreateInvitations = false;
            this.invitationEmails = [];
            this.invitationUpdates = new Invitation();
            this.invitations.clear();

            this.invitations.fetch({
                url: `/invitations/pitches/${store.pitch.value.id}`,
            });
        },
        // Update permissions of a new or existing invitation.
        updatePermissions(permissionUpdate: Record<string, any>) {
            const {id, permissionKey} = permissionUpdate;

            const invitation = this.invitations.first((inv: Invitation) => inv.id === id);

            if (invitation) {
                invitation.permissions = typeof permissionKey === 'string'
                    ? permissionKey
                    : [permissionKey];
            }

            if (SharePermission.key(permissionKey) != null) {
                this.updateOrCreateSync(id, permissionKey);

                this.removeFromDetach(id);
            } else {
                this.addToDetach(id);

                this.removeFromSync(id);
            }
        },
        /*
         * If an item with identifier already exists in the sync array, update it.
         * Otherwise create a new item in the sync array.
         */
        updateOrCreateSync(identifier: number|string, permissionKey: number) {
            const identifierKey = typeof identifier === 'string' ? 'email' : 'id';

            const syncItem = this.invitationUpdates.sync.find((sync: InvitationSync) => sync[identifierKey] === identifier);

            if (syncItem) {
                syncItem.permissions = [permissionKey];

                return;
            }

            const newSync: InvitationSync = {
                permissions: [permissionKey],
                [identifierKey]: identifier,
            };

            this.invitationUpdates.sync.push(newSync);
        },
        // Remove an item from the detach array.
        removeFromDetach(id: number) {
            const index = this.invitationUpdates.detach.indexOf(id);

            if (index > -1) {
                this.invitationUpdates.detach.splice(index, 1);
            }
        },
        // Add an item to the detach array.
        addToDetach(id: number) {
            // Prevent the same id from being added multiple times.
            const index = this.invitationUpdates.detach.indexOf(id);

            if (index < 0) {
                this.invitationUpdates.detach.push(id);
            }
        },
        // Remove an item from the sync array.
        removeFromSync(identifier: number|string) {
            const identifierKey = typeof identifier === 'string' ? 'email' : 'id';

            const index = this.invitationUpdates.sync.findIndex((sync: InvitationSync) => sync[identifierKey] === identifier);

            if (index > -1) {
                this.invitationUpdates.sync.splice(index, 1);
            }
        },
        // Hide the create invitations component and remove any data that was added.
        hideCreateInvitations() {
            this.showCreateInvitations = false;

            this.invitationEmails.forEach((invitation: ListItem) => this.removeFromSync(invitation.item));

            this.invitationEmails = [];
        },
        async saveInvitations() {
            this.invitationEmails.forEach((invitation: ListItem) => this.updateOrCreateSync(invitation.item, this.newInvitationsPermission));

            try {
                await this.invitationUpdates.update({
                    url: `/invitations/pitches/${store.pitch.value.id}`,
                });
            } catch {
                this.assignEmailErrors();

                return;
            }

            hideModal(this.modalName);
        },
        assignEmailErrors() {
            Object.keys(this.invitationUpdates.errors.errors).forEach((key: string) => {
                const match = key.match(/sync\.(\d+)\.email/);

                if (!match) return;

                // The group match is the second item in the match array.
                const index = +match[1];

                this.invitationEmails[index].error = true;
            });
        },
    },
});
