import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "row justify-content-between align-items-center" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('pitch.rearrangeSubjects.title')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn p-0",
            "data-test-id": "btn-close-card-icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('card-closed')))
          }, [
            _createVNode(_component_Fa, { icon: "cross-large" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        class: "w-100 mb-6",
        src: require('@/assets/images/subject-drag-example.png')
      }, null, 8, _hoisted_7),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('pitch.rearrangeSubjects.body')), 1),
      _createElementVNode("div", {
        class: "text-primary cursor-pointer",
        "data-test-id": "btn-close-card-link",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('card-closed')))
      }, _toDisplayString(_ctx.$t('pitch.rearrangeSubjects.actions.gotIt')), 1)
    ])
  ]))
}