import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133d0a2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "user-indicator square-sm rounded-circle border border-2 d-inline-flex align-items-center justify-content-center bg-primary"
}
const _hoisted_2 = { class: "text-white fw-normal fs-7 user-select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicedUsers, (user) => {
      return _withDirectives((_openBlock(), _createBlock(_component_ProfilePicture, {
        key: user.id,
        class: "user-indicator square-sm rounded-circle border border-2",
        user: user
      }, null, 8, ["user"])), [
        [
          _directive_tooltip,
          user.name,
          void 0,
          { hover: true }
        ]
      ])
    }), 128)),
    (_ctx.slicedUsers.count() !== _ctx.users.count())
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, " +" + _toDisplayString(_ctx.users.count() - _ctx.maxAmount), 1)
        ])), [
          [
            _directive_tooltip,
            _ctx.$t('sentences.andOthers', [_ctx.users.count() - _ctx.maxAmount]),
            void 0,
            { hover: true }
          ]
        ])
      : _createCommentVNode("", true)
  ]))
}