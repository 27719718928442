import Comment from '@/models/Comment';
import Delta from 'quill-delta';
import Op from 'quill-delta/src/Op';
import Quill from 'quill';
import cloneDeep from 'lodash/cloneDeep';
import emitter from '@/vendor/mitt';
import get from 'lodash/get';
import store from '@/store/pitches/edit';
import {v4} from 'uuid';

const createComment = (quill: Quill): void => {
    const newCommentUuid = v4();

    // Clone the old contents for old highlight references.
    const oldContents = cloneDeep(quill.getContents());

    quill.once('text-change', (delta: Delta) => {
        store.setNewCommentUpdate(delta);
    });

    // Add the new highlight.
    quill.format('highlight', newCommentUuid, 'user');

    /*
     * Get the diff between new and old contents.
     * These are the old highlight values that were overwritten by the new highlight.
     */
    const oldHighlightChange = quill.getContents().diff(oldContents);

    // Add the new highlight to the list of old highlights.
    oldHighlightChange.forEach((op: Op) => {
        if (op.attributes) {
            if (op.attributes.highlight === null) {
                // Remove the new highlight
                delete op.attributes.highlight;
            } else {
                // Add new highlight to old highlights
                op.attributes.highlight += `,${newCommentUuid}`;
            }
        }
    });

    // Add the old highlights with new highlight back to content.
    quill.updateContents(oldHighlightChange);

    store.setCurrentCommentUuid(newCommentUuid);

    emitter.emit('addNewCommentHighlight');
};

const removeHighlight = (quill: Quill): void => {
    // Reset the comment update in the store.
    store.setNewCommentUpdate();

    // Clone the old contents for old highlight references.
    const contents = cloneDeep(quill.getContents());

    // RegEx for the uuid with leading, trailing or no comma.
    const uuidRegex = new RegExp(`,${store.currentCommentUuid.value}|${store.currentCommentUuid.value},?`);

    /*
     * Map the ops that contain the current comment uuid highlight to
     * remove that highlight value. Leave all other ops unchanged.
     */
    const mappedOps = contents.map((op: Op) => {
        if (
            !op.attributes?.highlight
            || !uuidRegex.test(op.attributes.highlight)
        ) {
            return op;
        }

        op.attributes.highlight = op.attributes.highlight.replace(uuidRegex, '');

        return op;
    });

    // Get the diff between new and old contents.
    const deltaDiff = quill.getContents().diff(new Delta(mappedOps));

    // Update the Quill contents with the diff.
    quill.updateContents(deltaDiff);
};

const deleteComment = async (comment: Comment): Promise<void> => {
    // If the comment is already deleting, don't try to delete it again.
    if (comment.deleting) {
        return;
    }

    try {
        await comment.delete();
    } catch (e: any) {
        if (!e.response || !e.response.data.errors) throw e;
    }
};

/*
 * This checks the Quill Delta to see if there is any highlight left with
 * the passed uuid. If no highlight exists anymore, it will call a method
 * to delete the according comment.
 */
const checkDeleteComments = (uuidString: string, delta: Delta): void => {
    if (!store.currentComments.value) {
        return;
    }

    /*
     * Split the uuid string on commas in case a highlight is added for
     * several comments.
     */
    const uuids = uuidString.split(',');

    // Filter the uuids to only the ones which have no highlight anymore.
    const removedUuids = uuids.filter((uuid: string) => {
        return delta.ops.every((op: Op) => !get(op, 'attributes.highlight', '').includes(uuid));
    });

    // If no uuids are left in the filtered array, don't delete any comments.
    if (!removedUuids.length) {
        return;
    }

    // Find the corresponding comments.
    const uuidComments = store.currentComments.value.filter((c: Comment) => c.uuid != null && removedUuids.includes(c.uuid));

    uuidComments.each((comment: Comment) => deleteComment(comment));
};

export {
    checkDeleteComments,
    createComment,
    removeHighlight,
};
