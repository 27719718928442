
import BtnEditModal from '@/components/pitches/edit/BtnEditModal.vue';
import BtnShareModal from '@/components/pitches/edit/share/BtnShareModal.vue';
import Counters from '@/components/pitches/edit/Counters.vue';
import ExportDropdown from '@/components/pitches/edit/ExportDropdown.vue';
import FocusToggle from '@/components/pitches/edit/FocusToggle.vue';
import NameInput from '@/components/pitches/edit/NameInput.vue';
import Users from '@/components/pitches/edit/Users.vue';
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        BtnEditModal,
        BtnShareModal,
        Counters,
        ExportDropdown,
        FocusToggle,
        NameInput,
        Users,
    },
    setup() {
        return {
            canEdit: store.canEdit,
            pitch: store.pitch,
        };
    },
});
