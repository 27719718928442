import Delta from 'quill-delta';

/*
 * Create a matcher for the highlight blot to prevent the highlights from being
 * added on paste.
 */
const highlightMatcher = (node: Node, delta: Delta): Delta => {
    const highlightOp = delta.ops[0];

    return new Delta([{
        insert: highlightOp.insert,
    }]);
};

const matchers = [
    [
        '.comment-highlight',
        highlightMatcher,
    ],
];

export default matchers;
