import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AmountOf = _resolveComponent("AmountOf")!
  const _component_Instruction = _resolveComponent("Instruction")!
  const _component_QuillTextEditor = _resolveComponent("QuillTextEditor")!
  const _component_ConfirmAndContinue = _resolveComponent("ConfirmAndContinue")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'opacity-20': _ctx.isInactive})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({'current-border': _ctx.isCurrentScript})
    }, [
      (_ctx.script.subject)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.script.subject.name) + " ", 1),
            (_ctx.isCurrentScript)
              ? (_openBlock(), _createBlock(_component_AmountOf, {
                  key: 0,
                  amount: _ctx.wordCount,
                  "amount-unit-class": "text-primary",
                  class: "d-inline ms-4 fs-7",
                  of: _ctx.script.maxWords,
                  unit: _ctx.$t('words.words')
                }, null, 8, ["amount", "of", "unit"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showInstruction)
        ? (_openBlock(), _createBlock(_component_Instruction, {
            key: 1,
            class: "mb-6",
            script: _ctx.script,
            onToggleInstruction: _ctx.updateTooltipPosition
          }, null, 8, ["script", "onToggleInstruction"]))
        : _createCommentVNode("", true),
      _createVNode(_component_QuillTextEditor, {
        ref: "textEditor",
        "can-comment": _ctx.canComment,
        "can-edit": _ctx.canEdit,
        "is-active": _ctx.isCurrentScript,
        "model-value": _ctx.script.body,
        placeholder: _ctx.script.subject?.placeholder || _ctx.$t('script.placeholder'),
        onSelectionChange: _ctx.selectionChange,
        onTextChange: _ctx.textChange
      }, null, 8, ["can-comment", "can-edit", "is-active", "model-value", "placeholder", "onSelectionChange", "onTextChange"]),
      _withDirectives(_createVNode(_component_ConfirmAndContinue, {
        class: "mt-12",
        script: _ctx.script
      }, null, 8, ["script"]), [
        [_vShow, _ctx.isCurrentScript && _ctx.canEdit]
      ])
    ], 2)
  ], 2))
}