import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid px-6 vh-100 d-flex flex-column" }
const _hoisted_2 = { class: "row flex-fill overflow-hidden" }
const _hoisted_3 = { class: "col-3 max-h-100 overflow-y-auto no-scrollbar" }
const _hoisted_4 = { class: "col max-h-100 overflow-y-auto scrollbar ps-6" }
const _hoisted_5 = { class: "col-3 max-h-100 overflow-y-auto no-scrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_ScriptNav = _resolveComponent("ScriptNav")!
  const _component_ScriptList = _resolveComponent("ScriptList")!
  const _component_ScriptActions = _resolveComponent("ScriptActions")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_RearrangeSubjectCard = _resolveComponent("RearrangeSubjectCard")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createBlock(_component_WaitForResource, {
    class: "vh-100",
    "hide-error": _ctx.hideError,
    resource: _ctx.pitch,
    "show-error-details": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.pitch.id)
          ? (_openBlock(), _createBlock(_component_Nav, {
              key: 0,
              class: "border-bottom mb-6 bg-white"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ScriptNav)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ScriptList)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ScriptActions)
          ])
        ])
      ]),
      (_ctx.showIntroductionVideo && _ctx.introductionVideo)
        ? (_openBlock(), _createBlock(_component_VideoPlayer, {
            key: 0,
            class: "d-none",
            "play-on-mounted": "",
            "theatre-on-mounted": "",
            video: _ctx.introductionVideo,
            onTheatreModeClosed: _ctx.hideIntroductionVideo
          }, null, 8, ["video", "onTheatreModeClosed"]))
        : _createCommentVNode("", true),
      (_ctx.showRearrangeSubjectTip)
        ? (_openBlock(), _createBlock(_component_RearrangeSubjectCard, {
            key: 1,
            class: "position-fixed z-index-1 bottom-4 end-4 max-w-400px shadow",
            onCardClosed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRearrangeSubjectTip = false))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["hide-error", "resource"]))
}