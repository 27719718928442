
import {WsScript, WsScripts} from '@/models/Script';
import {defineComponent, ref} from 'vue';
import Media from '@/models/Media';
import Nav from '@/components/pitches/edit/Nav.vue';
import RearrangeSubjectCard from '@/components/pitches/RearrangeSubjectCard.vue';
import ResourceType from '@/library/enumerations/ResourceType';
import ScriptActions from '@/components/pitches/edit/scripts/Actions.vue';
import ScriptList from '@/components/pitches/edit/scripts/List.vue';
import ScriptNav from '@/components/pitches/edit/scripts/Nav.vue';
import VideoPlayer from '@/components/common/plyr/VideoPlayer.vue';
import onboardingStore from '@/store/onboarding';
import store from '@/store/pitches/edit';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        Nav,
        RearrangeSubjectCard,
        ScriptActions,
        ScriptList,
        ScriptNav,
        VideoPlayer,
    },
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();
        const newlyCreated = route?.query?.created != null;

        store.setSlug(props.slug);

        return {
            hideError: ref<boolean>(false),
            introductionVideo: ref<Media>(),
            pitch: store.pitch,
            // Hide the introduction video for now.
            showIntroductionVideo: ref<boolean>(newlyCreated && false),
            showRearrangeSubjectTip: ref<boolean>(newlyCreated),
        };
    },
    async created() {
        await store.fetch();

        this.hideError = true;

        store.setCurrentScript(this.pitch.scripts.first());

        if (
            this.showIntroductionVideo
            && this.pitch.template?.slug != null
        ) {
            await this.findIntroductionVideo();
        }
    },
    beforeUnmount() {
        (this.pitch.scripts as WsScripts).each((script: WsScript) => script.websocket.leave());

        this.pitch.websocket.leave();
    },
    methods: {
        async findIntroductionVideo() {
            await this.pitch.template!.fetch();

            const mediaResource = this.pitch.template!.resources.firstWhere('type', ResourceType.MEDIA);

            if (!mediaResource) {
                return;
            }

            this.introductionVideo = mediaResource.media.firstWhere('mimeType', 'video/mp4');
        },
        hideIntroductionVideo() {
            onboardingStore.completeStep('startPitch');

            this.showIntroductionVideo = false;
        },
    },
});
