
import {PropType, defineComponent} from 'vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import {Users} from '@/models/User';

export default defineComponent({
    components: {
        ProfilePicture,
    },
    props: {
        maxAmount: {
            type: Number,
            default: 5,
        },
        users: {
            type: Object as PropType<Users>,
            required: true,
        },
    },
    computed: {
        slicedUsers(): Users {
            return this.maxAmount
                ? this.users.slice(0, this.maxAmount)
                : this.users;
        },
    },
});
