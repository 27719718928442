import QuillCursors from 'quill-cursors';
import Websocket from '@/library/websockets/Websocket';

const createCursor = (user: Record<string, any>, cursorManager: QuillCursors): void => {
    cursorManager.createCursor(user.id, user.fullName || user.email, 'var(--bs-primary)');
};

const registerCursors = (pitchWebsocket: Websocket, scriptWebsocket: Websocket, cursorManager: QuillCursors): void => {
    if (!pitchWebsocket.channel || !scriptWebsocket.channel) {
        return;
    }

    pitchWebsocket.users.forEach((user: Record<string, any>) => createCursor(user, cursorManager));

    /*
     * Add a callback which creates cursors for all users
     * that are in the channel when it is joined.
     */
    pitchWebsocket.channel.here((users: Record<string, any>[]) => {
        users.forEach((user: Record<string, any>) => createCursor(user, cursorManager));
    });

    // Add a callback which creates a cursor for every user that joins the channel.
    pitchWebsocket.channel.joining((user: Record<string, any>) => createCursor(user, cursorManager));

    // Add a callback which removes a cursor for every user that joins the channel.
    pitchWebsocket.channel.leaving((user: Record<string, any>) => {
        cursorManager.removeCursor(user.id);
    });

    const activeFlags: number[] = [];

    /*
     * Add a callback which gets fired when a selection event is received.
     * This will update the given user's cursor location and toggle their flag.
     */
    scriptWebsocket.listenForWhisper('selection', (selectionData: Record<string, any>) => {
        cursorManager.moveCursor(selectionData.id, selectionData.selection);

        // Show the flag.
        cursorManager.toggleFlag(selectionData.id, true);

        // Remove the timeout if it is set.
        clearTimeout(activeFlags[selectionData.id]);

        // Add a timeout for 30 seconds, after which it hides the flag.
        activeFlags[selectionData.id] = setTimeout(() => {
            cursorManager.toggleFlag(selectionData.id, false);
        }, 30000);
    });
};

export {
    createCursor,
    registerCursors,
};
