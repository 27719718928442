
import {defineComponent} from 'vue';

/**
 * Displays an amount as a fraction of a total with optional a unit and label.
 * If the fraction is 1 or higher, the fraction will be red. If the fraction is
 * 1.15 or higher, the fraction will also be bold.
 */
export default defineComponent({
    props: {
        amount: {
            type: Number,
            required: true,
        },
        amountUnitClass: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        labelClass: {
            type: [Array, String],
            default: undefined,
        },
        of: {
            type: Number,
            required: true,
        },
        unit: {
            type: String,
            default: undefined,
        },
        withStateClasses: {
            default: true,
            type: Boolean,
        },
    },
    computed: {
        amountClass(): (string|undefined)[] {
            return [
                this.label ? 'ms-2' : undefined,
                this.unit ? 'me-1' : undefined,
            ];
        },
        amountUnitClasses(): (string|undefined)[] {
            return [
                ...(Array.isArray(this.amountUnitClass) ? this.amountUnitClass : [this.amountUnitClass]),
                ...this.stateClasses,
            ];
        },
        stateClasses(): (string|undefined)[] {
            if (!this.withStateClasses) {
                return [];
            }

            const percentage = this.amount / this.of;

            return [
                percentage > 1 ? 'text-danger' : undefined,
                percentage >= 1.15 ? 'fw-boldest' : undefined,
            ].filter((c: string|undefined) => !!c);
        },
    },
});
