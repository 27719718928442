
import {defineComponent, ref, toRef} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    setup() {
        return {
            focussed: ref(false),
            input: ref<HTMLElement>(),
            name: toRef(store.pitch.value, 'name'),
            pitch: store.pitch,
        };
    },
    computed: {
        invalid(): boolean {
            return !!this.pitch.errors.errors.name;
        },
        tooltip(): string {
            return this.invalid
                ? this.pitch.errors.errors.name[0]
                : this.name;
        },
    },
    watch: {
        name() {
            if (!this.focussed) {
                this.replaceRouteSlug();
            }
        },
    },
    methods: {
        replaceRouteSlug() {
            this.$router.replace({
                params: {
                    slug: this.pitch.slug,
                },
            });
        },
        async updateName() {
            this.focussed = false;

            this.pitch.errors.setErrors({});

            if (this.name !== this.pitch.original.name) {
                try {
                    await store.updatePitch();
                } catch (e: any) {
                    if (!e.response || !e.response.data.errors) throw e;

                    return;
                }

                this.replaceRouteSlug();
            }
        },
    },
});
