import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeScripts, (script, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: script.id
    }, [
      _createVNode(_component_Edit, {
        id: `script-${script.id}`,
        class: "pb-4 mb-6",
        index: index,
        script: script,
        onClickCapture: ($event: any) => (_ctx.setCurrentScript(script))
      }, null, 8, ["id", "index", "script", "onClickCapture"])
    ]))
  }), 128))
}