import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4400dc00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-4 align-items-center" }
const _hoisted_2 = { class: "col-3 d-flex align-items-center" }
const _hoisted_3 = { class: "col ps-6" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-4 col-xl-5 pe-0" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "name-input max-w-300px text-truncate" }
const _hoisted_8 = {
  key: 1,
  "data-placement": "bottom"
}
const _hoisted_9 = { class: "col-6 col-xl-5" }
const _hoisted_10 = {
  key: 0,
  class: "col-2"
}
const _hoisted_11 = { class: "col-3 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Users = _resolveComponent("Users")!
  const _component_NameInput = _resolveComponent("NameInput")!
  const _component_BtnEditModal = _resolveComponent("BtnEditModal")!
  const _component_Counters = _resolveComponent("Counters")!
  const _component_FocusToggle = _resolveComponent("FocusToggle")!
  const _component_BtnShareModal = _resolveComponent("BtnShareModal")!
  const _component_ExportDropdown = _resolveComponent("ExportDropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        class: "btn ps-0 pe-6",
        to: {name: 'home'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Fa, {
            class: "me-4",
            icon: "arrow-left"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('words.dashboard')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Users, { class: "ps-6 border-start" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_NameInput, { key: 0 }))
                : _withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.pitch.name), 1)
                  ])), [
                    [
                      _directive_tooltip,
                      _ctx.pitch.name,
                      void 0,
                      { hover: true }
                    ]
                  ])
            ]),
            (_ctx.canEdit)
              ? (_openBlock(), _createBlock(_component_BtnEditModal, {
                  key: 0,
                  class: "ms-4"
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Counters)
        ]),
        (_ctx.canEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_FocusToggle, { class: "float-end" })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_BtnShareModal, {
            key: 0,
            class: "me-2"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ExportDropdown)
    ])
  ]))
}