
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import PitchExportType from '@/library/enumerations/PitchExportType';
import {defineComponent} from 'vue';
import {exportPitch} from '@/components/pitches/edit/ExportPitch';
import {t} from '@/vendor/I18n';

export default defineComponent({
    components: {
        Dropdown,
    },
    setup() {
        return {
            exportOptions: PitchExportType.objects.map((object: Record<string, any>) => ({
                key: object.value,
                label: t('export.as', [PitchExportType.translate(object.key)]),
                onClick: () => exportPitch(object.value),
            } as DropdownOption)),
        };
    },
});
