
import {PropType, defineComponent, ref} from 'vue';
import store, {useScriptStore} from '@/store/pitches/edit';
import AmountOf from '@/components/common/AmountOf.vue';
import ConfirmAndContinue from '@/components/pitches/edit/scripts/ConfirmAndContinue.vue';
import Instruction from '@/components/pitches/edit/scripts/Instruction.vue';
import QuillTextEditor from '@/components/common/quill/QuillTextEditor.vue';
import {Tooltip} from 'bootstrap';
import {WsScript} from '@/models/Script';
import auth from '@/store/auth';
import {registerCursors} from '@/components/pitches/edit/scripts/ManageCursors';
import {throttle} from 'lodash';
import {toPlainText} from '@/library/quill/quillDelta';
import {wordCounter} from '@/library/helpers';

export default defineComponent({
    components: {
        AmountOf,
        ConfirmAndContinue,
        Instruction,
        QuillTextEditor,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        script: {
            type: Object as PropType<WsScript>,
            required: true,
        },
    },
    setup(props) {
        const {isCurrentScript, wordCount} = useScriptStore(props.script);
        const {canComment, canEdit, inFocusMode, isEmpty} = store;

        return {
            canComment,
            canEdit,
            inFocusMode,
            isCurrentScript,
            isEmpty,
            textEditor: ref<typeof QuillTextEditor>(),
            tooltip: ref<Tooltip>(),
            wordCount,
        };
    },
    computed: {
        showInstruction(): boolean {
            return !this.inFocusMode && this.canEdit;
        },
        isInactive(): boolean {
            return !this.isCurrentScript
                && !this.script.confirmedAt
                && !this.wordCount;
        },
    },
    watch: {
        isCurrentScript: {
            handler(v: boolean): void {
                if (v && this.textEditor) {
                    this.textEditor.focus();
                }

                this.updateTooltip();
            },
            immediate: true,
        },
        isEmpty() {
            this.updateTooltip();
        },
        inFocusMode() {
            this.updateTooltipPosition();
        },
        index() {
            this.updateTooltipPosition();
        },
        'script.body': function handler() {
            store.wordCounts.value[this.script.id as number] = wordCounter(toPlainText(this.script.body));
        },
    },
    mounted() {
        this.sendScriptUpdate = throttle(this.sendScriptUpdate, 250);

        if (!this.textEditor || !this.textEditor.quill) {
            return;
        }

        registerCursors(
            store.pitch.value.websocket,
            this.script.websocket,
            this.textEditor!.quill.getModule('cursors'),
        );

        const editorElement = this.textEditor.$el;

        this.tooltip = new Tooltip(editorElement, {
            customClass: 'script-edit-tooltip tooltip-pulsate-left z-index--1',
            offset: [0, -editorElement.offsetWidth * 0.6],
            placement: 'right',
            title: this.$t('script.tooltip'),
            trigger: 'manual',
        });

        this.updateTooltip();
    },
    beforeUnmount() {
        if (this.tooltip) {
            this.tooltip.dispose();
        }
    },
    methods: {
        selectionChange() {
            this.sendScriptUpdate(this.script, this.textEditor!.quill.getSelection());
        },
        textChange() {
            store.setScriptBody(this.script, this.textEditor!.quill.getContents());

            this.sendScriptUpdate(this.script, this.textEditor!.quill.getSelection());
        },
        sendScriptUpdate(script: WsScript, selection: Record<string, any>) {
            const diff = script.original.body.diff(script.body);

            if (diff.ops.length) {
                script.websocket.sendMessage('text', diff);

                if (
                    script.confirmedAt
                    && script.isEmpty
                ) {
                    store.unconfirmScript(this.script);
                }
            }

            script.websocket.sendMessage('selection', {
                id: auth.me.value!.id,
                selection,
            });
        },
        updateTooltip() {
            if (!this.tooltip) {
                return;
            }

            if (this.isCurrentScript && this.isEmpty) {
                this.tooltip.show();
            } else {
                this.tooltip.hide();
            }
        },
        updateTooltipPosition() {
            if (this.tooltip) {
                this.tooltip.update();
            }
        },
    },
});
