
import ActionNavBtn from '@/components/pitches/edit/scripts/ActionNavBtn.vue';
import {defineComponent} from 'vue';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        ActionNavBtn,
    },
    setup() {
        return {
            scriptActions: store.visibleScriptActions,
        };
    },
});
