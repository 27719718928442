import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.labelClass)
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.amountUnitClasses)
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.amountClass)
      }, _toDisplayString(_ctx.amount) + "/" + _toDisplayString(_ctx.of), 3),
      (_ctx.unit)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.unit), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}