/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import store from '@/store/pitches/edit';

const exportPitch = async (exportType: string): Promise<void> => {
    const config = {
        params: {
            type: exportType,
        },
    };

    const response = await axios.post(
        `/pitches/${store.pitch.value.slug}/export`,
        {},
        config,
    );

    if (!response.data.url) return;

    window.open(response.data.url, '_blank');
};

export {
    exportPitch,
};
