
import store, {ScriptActionKey} from '@/store/pitches/edit';
import {defineComponent} from 'vue';

interface Item {
    key: ScriptActionKey;
    translationKey: string;
    icon: string;
}

export default defineComponent({
    setup() {
        return {
            items: [
                {
                    key: 'videos',
                    translationKey: 'sentences.playVideo',
                    icon: 'play',
                },
                {
                    key: 'tips',
                    translationKey: 'sentences.showExamples',
                    icon: 'bolt',
                },
            ] as Item[],
            setCurrentScriptAction: store.setCurrentScriptAction,
        };
    },
});
