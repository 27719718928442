import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_EditModal = _resolveComponent("EditModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      class: "btn btn-light btn-circle btn-sm",
      "data-bs-target": "#pitch-edit",
      "data-bs-toggle": "modal"
    }), [
      _createVNode(_component_Fa, { icon: "edit" })
    ], 16),
    _createVNode(_component_EditModal)
  ], 64))
}