
import Pitch, {PitchData} from '@/models/Pitch';
import {defineComponent, ref} from 'vue';
import SaveModal from '@/components/pitches/SaveModal.vue';
import {hideModal} from '@/library/helpers';
import {pick} from 'lodash';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        SaveModal,
    },
    setup() {
        /*
         * These attributes are picked, because adding the scripts and
         * other data causes longer loading times when opening the modal.
         */
        const pitchAttributes = (pitch: Pitch): Partial<PitchData> => {
            return pick(pitch.attributes, [
                'id',
                'name',
                'pitchMoment',
                'slug',
                'tags',
                'template',
                'templateId',
            ]);
        };

        const pitch = ref<Pitch>(new Pitch(pitchAttributes(store.pitch.value)));

        return {
            modalName: 'pitch-edit',
            pitch,
            pitchAttributes,
        };
    },
    mounted() {
        this.$el.addEventListener('show.bs.modal', this.resetPitch);
    },
    beforeUnmount() {
        this.$el.removeEventListener('show.bs.modal', this.resetPitch);
    },
    methods: {
        pitchUpdated(pitch: Pitch) {
            hideModal(this.modalName);

            store.pitch.value.fill(this.pitchAttributes(pitch));
        },
        resetPitch() {
            this.pitch.resetStatus();

            this.pitch.resetToDefaults();

            this.pitch.fill(this.pitchAttributes(store.pitch.value));
        },
    },
});
