
import {defineComponent, ref} from 'vue';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import {EnumObject} from '@/library/enumerations/Enumeration';
import FormGroup from '@/components/common/form/FormGroup.vue';
import SharePermission from '@/library/enumerations/SharePermission';
import {t} from '@/vendor/I18n';

export default defineComponent({
    components: {
        Dropdown,
        FormGroup,
    },
    props: {
        modelValue: {
            type: Number,
            default: SharePermission.VIEW,
        },
    },
    emits: [
        'update:modelValue',
    ],
    setup() {
        const permissionOptions = SharePermission.objects.map((permission: EnumObject) => {
            const permissionTranslation = SharePermission.translateKey(permission.key);

            return {
                key: permission.value,
                label: t('pitch.share.canPermission', [permissionTranslation]),
            };
        });

        return {
            permission: ref<SharePermission>(SharePermission.VIEW),
            permissionOptions,
        };
    },
    computed: {
        dropdownTitle(): string|null {
            const selectedOption = this.permissionOptions.find((option: DropdownOption) => option.key === this.modelValue);

            return selectedOption?.label || null;
        },
    },
});
