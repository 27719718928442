import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "small me-2 me-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSwitch = _resolveComponent("FormSwitch")!
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('words.focus')), 1),
    _createVNode(_component_FormSwitch, {
      modelValue: _ctx.inFocusMode,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inFocusMode) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_Fa, {
      class: "cursor-pointer ms-4 d-none small focus-tooltip-close",
      icon: "cross-small",
      onClick: _ctx.hideTooltip
    }, null, 8, ["onClick"])
  ]))
}