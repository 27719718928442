import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AmountOf = _resolveComponent("AmountOf")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AmountOf, {
      amount: _ctx.wordCount,
      class: "small",
      label: _ctx.$t('pitch.edit.counters.amountOfWords'),
      "label-class": "text-secondary",
      of: _ctx.maxWords,
      unit: _ctx.$t('words.words')
    }, null, 8, ["amount", "label", "of", "unit"]),
    _createVNode(_component_AmountOf, {
      amount: _ctx.minuteCount,
      class: "ms-2 ms-xl-4 border-start ps-2 ps-xl-4 small",
      label: _ctx.$t('pitch.edit.counters.amountOfMinutes'),
      "label-class": "text-secondary",
      of: _ctx.maxMinutes,
      unit: _ctx.$t('words.minutesShort')
    }, null, 8, ["amount", "label", "of", "unit"])
  ]))
}