/*
 * To make Quill 'Comment Only', we need to override certain keyboard binds with
 * close to all variations of secondary keys (like shift + delete or ctrl + delete).
 */
const keyboardOverrides = {
    backspace: {
        key: 'backspace',
        handler() {
            return false;
        },
    },
    altBackspace: {
        key: 'backspace',
        altKey: true,
        handler() {
            return false;
        },
    },
    ctrlBackspace: {
        key: 'backspace',
        shortKey: true,
        handler() {
            return false;
        },
    },
    shiftBackspace: {
        key: 'backspace',
        shiftKey: true,
        handler() {
            return false;
        },
    },
    delete: {
        key: 'delete',
        handler() {
            return false;
        },
    },
    altDelete: {
        key: 'delete',
        altKey: true,
        handler() {
            return false;
        },
    },
    ctrlDelete: {
        key: 'delete',
        shortKey: true,
        handler() {
            return false;
        },
    },
    shiftDelete: {
        key: 'delete',
        shiftKey: true,
        handler() {
            return false;
        },
    },
    enter: {
        key: 'enter',
        handler() {
            return false;
        },
    },
    shiftEnter: {
        key: 'enter',
        shiftKey: true,
        handler() {
            return false;
        },
    },
    tab: {
        key: 'tab',
        handler() {
            return false;
        },
    },
    pasteShortcut: {
        key: 'v',
        shortKey: true,
        handler() {
            return false;
        },
    },
};

/**
 * Prevents the event's default action.
 */
const override = (ev: Event): void => ev.preventDefault();

/*
 * We also need to disable the normal keypress events as well as the cut,
 * paste and drop events.
 */
const overrideEventListeners = (contentEditor: Element): void => {
    contentEditor.addEventListener('keypress', override);
    contentEditor.addEventListener('cut', override);
    contentEditor.addEventListener('paste', override);
    contentEditor.addEventListener('drop', override);
};

/**
 * Removes the added overrides.
 */
const removeOverrideEventListeners = (contentEditor: Element): void => {
    contentEditor.removeEventListener('keypress', override);
    contentEditor.removeEventListener('cut', override);
    contentEditor.removeEventListener('paste', override);
    contentEditor.removeEventListener('drop', override);
};

export {
    keyboardOverrides,
    overrideEventListeners,
    removeOverrideEventListeners,
};
