
import {defineComponent, toRef} from 'vue';
import Draggable from 'vuedraggable';
import NavItem from '@/components/pitches/edit/scripts/NavItem.vue';
import Script from '@/models/Script';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        Draggable,
        NavItem,
    },
    setup() {
        return {
            scripts: toRef(store.pitch.value, 'scripts'),
        };
    },
    methods: {
        updateIndex() {
            this.scripts.all().forEach((script: Script, index: number) => {
                script.index = index;
            });

            store.updatePitch();
        },
    },
});
