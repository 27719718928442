import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavToggle = _resolveComponent("NavToggle")!
  const _component_NavDraggable = _resolveComponent("NavDraggable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavToggle, { class: "mb-6" }),
    _createVNode(_Transition, { name: "fade-left" }, {
      default: _withCtx(() => [
        (!!_ctx.scriptNavVisible)
          ? (_openBlock(), _createBlock(_component_NavDraggable, {
              key: 0,
              class: "script-nav"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}