
import {PropType, defineComponent} from 'vue';
import store, {ScriptAction} from '@/store/pitches/edit';

export default defineComponent({
    props: {
        action: {
            type: Object as PropType<ScriptAction>,
            required: true,
        },
    },
    setup() {
        return {
            currentScriptAction: store.currentScriptAction,
            setCurrentScriptAction: store.setCurrentScriptAction,
        };
    },
    computed: {
        active(): boolean {
            return this.action.key === this.currentScriptAction?.key;
        },
        icon(): string {
            return this.active
                ? this.action.iconSelected
                : this.action.icon;
        },
    },
    methods: {
        setScriptAction(): void {
            this.setCurrentScriptAction(this.active ? undefined : this.action.key);
        },
    },
});
